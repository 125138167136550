<template>
    <div id="app-index">

        <!-- Esto es un ejemplo que se pueden diferentes grupos de notificaciones,
            las cuales pueden estar posicionadas en distintos lados, simplemente se indica en grupo en la noti -->
        <notifications group="app" position="top right" style="top: 10px !important; z-index: 10000 !important;" />
        <notifications group="app2" position="bottom right" />

     
        <div>

            <router-view />

        </div>

    </div>
</template>

<script>
    export default {
        name: 'app-index'
    };
</script>

<style>
</style>
