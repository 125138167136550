<template>

    <div class="row" style="background-color: #EFEFFF;">

        <div class="col-lg-4 col-md-3 col-0">
        </div>

        <div class="col-lg-4 col-md-6 col-12" style="padding-top: 30px;">

            <div class="login-box card text-center" style="width: 100%;">
                <div class="card-body">
                    <div class="form-horizontal form-material">
                        <div class="form-group">
                            <div class="col-xs-12">
                                <h3> Recuperar Contraseña </h3>
                                <p class="text-muted"> Ingrese su correo y se le enviará una nueva contraseña! </p>
                            </div>
                        </div>
                        <div class="form-group ">
                            <div class="col-xs-12">
                                <input type="email" class="form-control" id="validationCustom06" placeholder="Correo" v-model="usuario.login">

                                <div v-show="v$.usuario.login.$error" class="invalid-vuelidate-error">
                                    Debe completar el correo electrónico
                                </div>

                            </div>
                        </div>
                        <div class="form-group text-center m-t-20">
                            <div class="col-xs-12">
                                <button class="btn btn-primary btn-lg btn-block btn-rounded text-uppercase waves-effect waves-light" type="button" v-on:click="remember()"> Recuperar </button>
                            </div>
                        </div>
                        <div class="form-group m-b-0">
                            <div class="col-sm-12 text-center">
                                ¿Ya tiene una cuenta? <router-link :to="{name: 'login'}" class="text-info m-l-5"><b>Iniciar Sesión</b></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-lg-4 col-md-3 col-0">
        </div>

    </div>


</template>

<script>
    import cuentaService from '@/app/login/services/cuentaService.js';
    import { useVuelidate } from '@vuelidate/core';
    import { required, email } from '@vuelidate/validators';

    export default {
        setup() {
            return { v$: useVuelidate() }
        },
        data() {
            return {
                usuario: {
                    login: ''
                }
            }
        },
        methods: {

            remember: function () {

                this.v$.$validate();

                if (!this.v$.$invalid) {

                    this.$loading.show();

                    cuentaService.postRemember(this.usuario).then(response => {

                        if (response.data.ok) {

                            this.$notify.Notificacion(1, "Su contraseña se recuperó con éxito, revise su correo electronico para obtener su nueva contraseña");
                            this.limpiarCampos();

                            this.$loading.hide();
                            this.$router.push('/login');
                            

                        } else {

                            if (response.data.obj == -1) {
                                this.$notify.Notificacion(2, response.data.msg);
                            } else {
                                this.$notify.Notificacion(2, "No se pudo registrar recuperar su contraseña. Favor consulte con su proveedor(" + response.data.msg + ").");
                            }

                            this.$loading.hide();

                        }
                    }).catch(error => {

                        if (error.response.data != null) {
                            this.$notify.Notificacion(2, error.response.data.error_description);
                        } else {
                            this.$notify.Notificacion(2, "Se ha producido un error al intentar recuperar su contraseña. Favor contacte a asu proveedor.");
                        }

                        this.$loading.hide();

                    })

                } else {
                    this.$notify.Notificacion(3, "Debe llegar todos los campos requeridos");
                }
            },

            limpiarCampos: function () {
                this.usuario.login = '';
            }

        },
        validations: {
            usuario: {
                login: { required, email }
            }

        }

    };

</script>

<style>

    html, body {
        background-color: #EFEFFF !important;
    }

    .row {
        margin: 0px !important;
    }

</style>