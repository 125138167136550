<template>

    <div class="row" style="height: 100vh;">

        <div class="col-lg-9 col-md-8 d-none d-lg-block d-md-block" style="padding-left: 0px !important; padding-right: 0px !important;">

            <img src="../../../assets/img/Logo_banner2.png" style="width: 100%; height: 100%;" />

        </div>
        <div class="col-lg-3 col-md-4 col-sm-12" style="background-color: #EFEFFF;">

            <!--<form class="form-horizontal form-material text-center" id="loginform" style="padding-top: 30px;">-->
            <div class="form-horizontal form-material text-center" style="padding-top: 30px;">

                <!--<h3 class="text-center m-b-20"> Iniciar Sesión </h3>-->

                <div class="form-group" style="padding-top: 30px; padding-bottom: 30px;">
                    <div class="col-xs-12 text-center">

                        <img src="/assets/images/Logo_Horizontal_Xpress_Full_Color.png" style="max-height: 200px; max-width: 200px;" />

                    </div>
                </div>


                <h3 class="text-center m-b-20"> Iniciar Sesión </h3>

                <div class="text-center">
                    <hr />
                </div>

                <div class="form-group">
                    <div class="col-xs-12">
                        <input class="form-control" type="email" required="" placeholder="Correo" v-model="variables.usuario.login" @keydown.enter.prevent="VerificarCuentasLogin();">
                        <div class="text-danger" v-if="v$.variables.usuario.login.$error"> Debe completar el correo electrónico o digitar un correo válido </div>
                    </div>
                </div>
                <div class="form-group">
                    <div class="col-xs-12">
                        <input class="form-control" type="password" required="" placeholder="Contraseña" v-model="variables.usuario.password" @keydown.enter.prevent="VerificarCuentasLogin();">
                        <div class="text-danger" v-if="v$.variables.usuario.password.$error"> Debe completar la contraseña </div>
                    </div>
                </div>
                <div class="form-group row">
                    <div class="col-md-12">
                        <div class="d-flex no-block align-items-center">
                            <div class="ml-auto">
                                <router-link to="/recover" class="text-muted"><i class="fas fa-lock m-r-5"></i> ¿Olvidó su contraseña? </router-link>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group text-center">
                    <div class="col-xs-12 p-b-20">
                        <button class="btn btn-block btn-lg btn-primary btn-rounded" v-on:click="VerificarCuentasLogin();" type="button"> Ingresar </button>
                    </div>
                </div>
                <!--<div class="row">
                <div class="col-xs-12 col-sm-12 col-md-12 m-t-10 text-center">
                    <div class="social">
                        <button class="btn  btn-facebook" data-toggle="tooltip" title="Login with Facebook"> <i aria-hidden="true" class="fab fa-facebook-f"></i> </button>
                        <button class="btn btn-googleplus" data-toggle="tooltip" title="Login with Google"> <i aria-hidden="true" class="fab fa-google-plus-g"></i> </button>
                    </div>
                </div>
            </div>-->
                <div class="form-group m-b-0">
                    <div class="col-sm-12 text-center">
                        ¿No tiene una cuenta? <router-link to="/register" class="text-info m-l-5"> <b> Registrese </b> </router-link>
                        <br />
                    </div>
                </div>
            </div>

        </div>


        <VueFinalModal v-model="variables.showAccounts"
                       content-class="modal-dialog modal-dialog-centered modal-md"
                       content-transition="vfm-fade"
                       overlay-transition="vfm-fade">
            <div class="modal-content">

                <div class="modal-header">
                    <h4 class="modal-title"> Listado de Cuenta </h4>
                    <!--<button type="button" class="close" v-on:click="variables.showAccounts = false;">&times;</button>-->
                </div>
                <div class="modal-body">
                    <table class="table">
                        <thead class="thead-dark">
                            <tr>
                                <th></th>
                                <th>Cuenta</th>
                                <th>Nombre</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="cuenta in lists.cuentas" v-bind:key="cuenta">
                                <td><button type="button" class="close"><i class="ti-check-box" v-on:click="Login(cuenta)"> </i></button></td>
                                <td>{{cuenta.Email}}</td>
                                <td>{{cuenta.Nombre}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" @click="variables.showAccounts = false;"> Cerrar </button>
                </div>
            </div>
        </VueFinalModal>

        <VueFinalModal v-model="variables.showPlanVencimientoModal"
                       content-class="modal-dialog modal-dialog-centered modal-md"
                       content-transition="vfm-fade"
                       overlay-transition="vfm-fade"
                       :esc-to-close="false"
                       :click-to-close="false"
                       @before-close="ModalPlanVencimientoClose">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title"> Vencimiento de Plan </h4>
                </div>
                <div class="modal-body">

                    <div class="row">

                        <div class="col-12">

                            <p>
                                Estimado(a) Cliente:
                                <br />
                                Su plan <strong>se encuentra vencido hace {{ variables.diasVencido }} día(s)</strong>. <br />
                                Para continuar con el uso de la aplicación debe renovar el plan al siguiente <strong><a href="https://technosoftcr.com/cuenta/Login.aspx" target="_blank">LINK</a></strong> <br />
                                Si ya realizó el pago puede comunicarse al <strong>2105-8095</strong> o puede enviar el comprobante de pago a <strong>cobros@facelcr.com</strong> indicando los siguientes datos: correo de su cuenta de inventarios, nombre, cédula y número telefónico. Estamos para servirle.

                            </p>

                        </div>

                    </div>

                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-light" @click="variables.showPlanVencimientoModal = false;"> Cerrar </button>
                </div>
            </div>
        </VueFinalModal>

    </div>

</template>

<script src="../controllers/loginCtrl.js"></script>

<style>

/*    html, body {
        height: 100%;
    }
*/

    .row {
        margin: 0px !important;
    }


</style>