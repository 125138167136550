import { createApp } from 'vue'
import App from './App-index.vue';
import router from './router-index';

import notifications from '@kyvg/vue3-notification';
import { NotificacionesCustom } from './../common/notify'; // Custom

import 'vue-loading-overlay/dist/css/index.css';
import { LoadingPluginCustom } from './../common/loadingOverlay'; // Custom

import '@/assets/css/personalizado.css';

import { createVfm } from 'vue-final-modal';
import 'vue-final-modal/style.css';

import { Filters } from './../common/filters'; // Custom

import '@vuepic/vue-datepicker/dist/main.css'

import 'vue3-form-wizard/dist/style.css';


const app = createApp(App);


app.use(notifications);
app.use(createVfm);
app.use(router);


// Custom

app.use(NotificacionesCustom);
app.use(LoadingPluginCustom);
app.use(Filters);


app.mount('#app');
