<template>

    <div class="row" style="background-color: #EFEFFF;">

        <div class="col-lg-4 col-md-3 col-0">
        </div>

        <div class="col-lg-4 col-md-6 col-12" style="padding-top: 30px;">

            <div class="login-box card text-center" style="width: 100%;">
                <div class="card-body">
                    <div class="form-horizontal form-material">
                        <h3 class="text-center m-b-20"> Confirmar Cuenta </h3>
                        <div class="form-group ">
                            <div class="col-xs-12">
                                <input class="form-control" type="password" required placeholder="Contraseña" v-model="cuenta.Password">
                            </div>

                            <div v-show="v$.cuenta.Password.$error" class="invalid-vuelidate-error">
                                Debe completar el password con un minimo de 6 caracteres
                            </div>

                        </div>
                        <div class="form-group">
                            <div class="col-xs-12">
                                <input class="form-control" type="password" required placeholder="Confirmación Contraseña" v-model="cuenta.PasswordConfirm">
                            </div>

                            <div v-show="v$.cuenta.PasswordConfirm.$error" class="invalid-vuelidate-error">
                                Debe completar la confirmación con un minimo de 6 caracteres
                            </div>

                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <div class="d-flex no-block align-items-center">
                                    <div class="ml-auto">
                                        <!--<router-link :to="{name: 'recover'}" class="text-muted"><i class="fas fa-lock m-r-5"></i> Olvido su contraseña?</router-link>-->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--<div class="form-group row">
        <div class="col-md-12">
            <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck1">
                <label class="custom-control-label" for="customCheck1">Yo acepto todos los <a>Términos y Condiciones</a></label>
            </div>
        </div>
    </div>-->
                        <div class="form-group text-center p-b-20">
                            <div class="col-xs-12">
                                <button class="btn btn-primary btn-lg btn-block btn-rounded text-uppercase waves-effect waves-light" v-on:click="confirm" type="button"> Confirmar </button>
                            </div>
                        </div>
                        <div class="form-group m-b-0">
                            <div class="col-sm-12 text-center">
                                ¿Ya tiene una cuenta? <router-link :to="{name: 'login'}" class="text-info m-l-5"><b> Iniciar Sesión </b></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-lg-4 col-md-3 col-0">
        </div>

    </div>

</template>

<script>
    import cuentaService from '@/app/login/services/cuentaService.js';
    import config from '@/config.js';
    import { useVuelidate } from '@vuelidate/core';
    import { required, minLength } from '@vuelidate/validators';

    export default {
        setup() {
            return { v$: useVuelidate() }
        },
        data() {
            return {
                cuenta: {
                    Codigo: this.$route.params.cuenta,
                    Email: "",
                    Nombre: "",
                    Compannia: "",
                    Password: "",
                    Telefono: 0,
                    Pais: 188,
                    Identificacion: "",
                    UltimoAcceso: "2020-05-25T04:03:03.424Z",
                    Pregunta: "",
                    Respuesta: "",
                    Actividad: 1,
                    estaConfirmada: false,
                    Confirmacion: this.$route.params.codigo,
                    Aplicacion: config.app_cod,
                    PasswordConfirm: "",
                    Comentario: ""
                }

            }
        },
        validations() {
            return {
                cuenta: {
                    Password: { required, minLength: minLength(6) },
                    PasswordConfirm: { required, minLength: minLength(6) }
                }
            }

        },
        methods: {
            setTouched(theModel) {
                if (theModel == 'Password' || theModel == 'all') {
                    this.v$.cuenta.Password.$touch()
                }
                if (theModel == 'PasswordConfirm' || theModel == 'all') {
                    this.v$.cuenta.PasswordConfirm.$touch()
                }

            },

            confirm: function () {

                this.setTouched('all');

                if (!this.v$.$invalid) {

                    if (this.cuenta.Password != this.cuenta.PasswordConfirm) {
                        this.$notify.Notificacion(3, "La contraseña y la confirmacion deben ser iguales. Favor verifique");
                        return;
                    }

                    this.$loading.show();

                    cuentaService.postConfirm(this.cuenta).then(response => {

                        if (response.data != null) {

                            switch (response.data) {
                                case "0":
                                    this.$notify.Notificacion(1, "La cuenta con ese confirmó con éxito, proceda a Iniciar Sesión");
                                    this.$router.push('/login');
                                    break;
                                case "-1":
                                    this.$notify.Notificacion(3, "La cuenta ya se encuentra registrada, debe Iniciar Sesión or Recuperar la contraseña");
                                    break;
                                case "-2":
                                    this.$notify.Notificacion(3, "La cuenta no puede ser confirmada porque no existe. Favor consulte con su proveedor");
                                    break;
                                default:
                                    this.$notify.Notificacion(2, "No se pudo confirmar su cuenta. Favor verifique con su proveedor");
                                    break;
                            }

                        } else {
                            this.$notify.Notificacion(2, "No se pudo confirmar su cuenta. Favor verifique con su proveedor");
                        }

                        this.$loading.hide();

                    }).catch(error => {
                        console.log('There was an error:', error.response);
                        this.$notify.Notificacion(2, "No se pudo confirmar su cuenta. Favor verifique con su proveedor");
                        this.$loading.hide();
                    })
                }
            }
        }

        //created() {
        //    cuentaService.postToken()
        //        .then(response => {
        //            this.event = response.data
        //        })
        //        .catch(error => {
        //            //console.log('There was an error:', error.response)
        //        })
        //}
    };
</script>

<style>

    html, body {
        background-color: #EFEFFF !important;
    }

    .row {
        margin: 0px !important;
    }

</style>