import { createRouter, createWebHashHistory } from "vue-router"; // createWebHistory,

// TENER CUIDADO
// Al declarar un Route se debe especificar un "name", cuidar que dicho nombre no se repita, sino habr� problemas con dichos router (los que tienen el mismo nombre)

import Login from '@/app/login/views/Login.vue';
import LostConnection from '@/app/lostconnection/views/LostConnection.vue';

import Registro from '@/app/cuenta/views/Registro.vue';
import Confirmar from '@/app/cuenta/views/ConfirmarCuenta.vue';
import Recuperar from '@/app/cuenta/views/RecuperarContrasenna.vue';
import InvitacionExistente from '@/app/cuenta/views/InvitacionExistente.vue';
import InvitacionNuevo from '@/app/cuenta/views/InvitacionNueva.vue';



const routes = [
    {
        path: "/",
        redirect: "/login"
    },
    {
        path: "/login",
        name: "login",
        component: Login
    },

    {
        path: '/register',
        name: 'register',
        component: Registro
    },
    {
        path: '/confirm/:cuenta/:codigo',
        name: 'confirm',
        component: Confirmar
    },
    {
        path: '/recover',
        name: 'recover',
        component: Recuperar
    },
    {
        path: '/invitation/accept/:cuenta/:codigo',
        name: 'invitationAccept',
        component: InvitacionExistente
    },
    {
        path: '/invitation/new/:cuenta/:codigo',
        name: 'invitationNew',
        component: InvitacionNuevo
    },


    {
        path: "/lostConnection",
        name: "lostConnection",
        component: LostConnection
    },
    // Esto agarra todo lo que no exista declarado como Route
    {
        path: '/:catchAll(.*)',
        redirect: "/"
    }
];


const router = createRouter({
    history: createWebHashHistory(`${process.env.BASE_URL}index/`),
    base: `${process.env.BASE_URL}index/`,
    routes,
});

export default router;
