<template>

    <div class="row" style="background-color: #EFEFFF;">

        <div class="col-lg-4 col-md-3 col-0">
        </div>

        <div class="col-lg-4 col-md-6 col-12" style="padding-top: 30px;">

            <div class="login-box card text-center" style="width: 100%;">
                <div class="card-body">
                    <div class="form-horizontal form-material">
                        <h3 class="text-center m-b-20"> Confirmar Invitación </h3>

                        <div class="form-group">
                            <div class="col-xs-12">
                                <input class="form-control" type="password" required placeholder="Contraseña" v-model="usuario.password">
                            </div>

                            <div v-show="v$.usuario.password.$error" class="invalid-vuelidate-error">
                                Debe completar el password con un minimo de 6 caracteres
                            </div>

                        </div>

                        <div class="form-group">
                            <div class="col-xs-12">
                                <input class="form-control" type="password" required placeholder="Confirmación Contraseña" v-model="usuario.passwordConfirm">
                            </div>

                            <div v-show="v$.usuario.passwordConfirm.$error" class="invalid-vuelidate-error">
                                Debe completar la confirmación con un minimo de 6 caracteres
                            </div>

                        </div>
                        <div class="form-group row">
                            <div class="col-md-12">
                                <div class="d-flex no-block align-items-center">
                                    <div class="ml-auto">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group text-center p-b-20">
                            <div class="col-xs-12">
                                <button class="btn btn-primary btn-lg btn-block btn-rounded text-uppercase waves-effect waves-light" v-on:click="AcceptInvitation()" type="button">Confirmar</button>
                            </div>
                        </div>
                        <div class="form-group m-b-0">
                            <div class="col-sm-12 text-center">
                                ¿Ya tiene una cuenta? <router-link :to="{name: 'login'}" class="text-info m-l-5"><b>Iniciar Sesión</b></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-lg-4 col-md-3 col-0">
        </div>

    </div>

</template>

<script>
    import cuentaService from '@/app/login/services/cuentaService.js';
    import config from '@/config.js';
    import { useVuelidate } from '@vuelidate/core'
    import { required,minLength } from '@vuelidate/validators'

    export default {
        setup() {
            return { v$: useVuelidate() }
        },
        data() {
            return {
                usuario: {
                    usuario_id: this.$route.params.cuenta,
                    login: '',
                    nombre: '',
                    password:'',
                    passwordConfirm:'',
                    tipo: '',
                    Aplicacion: config.app_cod,
                    confirmacion: this.$route.params.codigo,
                    ciaFavorita: 0,
                    cta_id:''
                },

            }
        },
        methods: {

            setTouched(theModel) {

                this.v$.usuario.$reset();

                if (theModel == 'Password' || theModel == 'all') {
                    this.v$.usuario.password.$touch()
                } 
                if (theModel == 'PasswordConfirm' || theModel == 'all') {
                    this.v$.usuario.passwordConfirm.$touch()
                } 

            },

            AcceptInvitation: function () {

                this.setTouched('all');

                if (!this.v$.$invalid) {

                    if (this.usuario.password != this.usuario.passwordConfirm) {
                        this.$notify.Notificacion(3,"La contraseña y la confirmacion deben ser iguales. Favor verifique");
                        return;
                    }

                    this.$loading.show();

                    cuentaService.postAcceptInviteUser(this.usuario).then(response => {

                        if (response.data != null) {

                            switch (response.data) {
                                case 0:
                                    this.$notify.Notificacion(1, "La invitacion se realizó con éxito, proceda a Iniciar Sesión");
                                    this.$router.push('/login');
                                    break;
                                case (-3 || -2):
                                    this.$notify.Notificacion(3, "Invitación inválida. Solicite que lo inviten nuevamente");
                                    break;
                                case -4:
                                    this.$notify.Notificacion(3, "Esta invitacion ya fue aceptada, proceda a Iniciar Sesión o recupere la contraseña");
                                    break;
                                default:
                                    this.$notify.Notificacion(2, "No se pudo procesar la invitación. Favor verifique con su proveedor");
                                    break;
                            }

                        } else {
                            this.$notify.Notificacion(2, "No se pudo confirmar su cuenta. Favor verifique con su proveedor");
                        }

                        this.$loading.hide();

                    }).catch(error => {
                        this.$notify.Notificacion(2, "No se pudo confirmar su cuenta. Favor verifique con su proveedor");
                        console.log('There was an error:', error);
                        this.$loading.hide();
                    })

                } else {

                    this.$notify.Notificacion(3, "Debe llegar todos los campos requeridos");

                }
            }
        },
        validations() {
            return {
                usuario: {
                    password: { required, minLength: minLength(6) },
                    passwordConfirm: { required, minLength: minLength(6) }
                }
            }

        },
    };
</script>

<style>

    html, body {
        background-color: #EFEFFF !important;
    }

    .row {
        margin: 0px !important;
    }

</style>