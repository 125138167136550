<template>

    <div class="row" style="background-color: #EFEFFF;">

        <div class="col-lg-4 col-md-3 col-0">
        </div>

        <div class="col-lg-4 col-md-6 col-12" style="padding-top: 30px;">

            <div class="login-box card text-center" style="width: 100%;">
                <div class="card-body">
                    <div class="form-horizontal form-material">
                        <h3 class="text-center m-b-20">Registro</h3>
                        <div class="form-group ">
                            <div class="col-xs-12">
                                <input class="form-control" type="email" required="" placeholder="Correo" v-model="cuenta.Email">
                            </div>

                            <div v-show="v$.cuenta.Email.$error" class="invalid-vuelidate-error">
                                Debe completar el correo electrónico
                            </div>

                        </div>
                        <div class="form-group">
                            <div class="col-xs-12">
                                <input class="form-control" type="text" required="" placeholder="Nombre" v-model="cuenta.Nombre">
                            </div>

                            <div v-show="v$.cuenta.Nombre.$error" class="invalid-vuelidate-error">
                                Debe completar el nombre
                            </div>

                        </div>
                        <!--<div class="form-group row">
        <div class="col-md-12">
            <div class="custom-control custom-checkbox">
                <input type="checkbox" class="custom-control-input" id="customCheck1">
                <label class="custom-control-label" for="customCheck1">Yo acepto todos los <a>Términos y Condiciones</a></label>
            </div>
        </div>
    </div>-->
                        <div class="form-group text-center p-b-20">
                            <div class="col-xs-12">
                                <button class="btn btn-primary btn-lg btn-block btn-rounded text-uppercase waves-effect waves-light" v-on:click="register" type="button">Registrarse</button>
                            </div>
                        </div>
                        <div class="form-group m-b-0">
                            <div class="col-sm-12 text-center">
                                ¿Ya tiene una cuenta? <router-link :to="{name: 'login'}" class="text-info m-l-5"><b>Iniciar Sesión</b></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-lg-4 col-md-3 col-0">
        </div>

    </div>

</template>

<script>
    import cuentaService from '@/app/login/services/cuentaService.js';
    import config from '@/config.js';
    import { useVuelidate } from '@vuelidate/core'
    import { required, email } from '@vuelidate/validators'

    export default {
        setup() {
            return { v$: useVuelidate() }
        },
        data() {
            return {
                cuenta: {
                    Codigo: 0,
                    Email: "",
                    Nombre: "",
                    Compannia: "",
                    Password: "",
                    Telefono: 0,
                    Pais: 188,
                    Identificacion: "",
                    UltimoAcceso: "2020-05-25T04:03:03.424Z",
                    Pregunta: "",
                    Respuesta: "",
                    Actividad: 1,
                    estaConfirmada: false,
                    Confirmacion: "",
                    Aplicacion: config.app_cod,
                    PasswordConfirm: "",
                    Comentario: ""
                }

            }
        },
        validations() {
            return {
                cuenta: {
                    Email: { required, email },
                    Nombre: { required }
                }
            }

        },
        methods: {

            register: function () {

                this.v$.$validate();

                if (!this.v$.$invalid) {

                    this.$loading.show();

                    cuentaService.postAdd(this.cuenta).then(response => {

                        if (response.data != null) {

                            switch (response.data) {
                                case -1:
                                    this.$notify.Notificacion(3, "La cuenta con ese correo ya se encuentra registrada");
                                    this.cuenta.Email = "";
                                    this.cuenta.Nombre = "";
                                    break;
                                case -2:
                                    this.$notify.Notificacion(2, "No se pudo registrar su cuenta. Favor verifique con su proveedor");
                                    break;
                                default:
                                    if (response.data > 0) {
                                        this.$notify.Notificacion(1, "La cuenta de correo se registró correctamente, dentro de poco recibirá un correo para confirmar su registro");
                                    } else {
                                        this.$notify.Notificacion(2, "No se pudo registrar su cuenta. Favor verifique con su proveedor");
                                    }
                                    break;
                            }

                        }

                        this.$loading.hide();

                        this.v$.cuenta.$reset();


                    }).catch(error => {
                        console.log('There was an error:', error.response)
                        this.$notify.Notificacion(2, "No se pudo registrar su cuenta. Favor verifique con su proveedor");
                        this.$loading.hide();
                    })
                }
            }
        }

        //created() {
        //    cuentaService.postToken()
        //        .then(response => {
        //            this.event = response.data
        //        })
        //        .catch(error => {
        //            //console.log('There was an error:', error.response)
        //        })
        //}
    };
</script>

<style>

    html, body {
        background-color: #EFEFFF !important;
    }

    .row {
        margin: 0px !important;
    }

</style>