<template>

    <div class="row" style="background-color: #EFEFFF;">

        <div class="col-lg-4 col-md-3 col-0">
        </div>

        <div class="col-lg-4 col-md-6 col-12" style="padding-top: 30px;">

            <div class="login-box card text-center" style="width: 100%;">
                <div class="card-body">
                    <div class="form-horizontal form-material">
                        <h3 class="text-center m-b-20">Aceptar Invitacion</h3>
                        <p>
                            ¿Desea aceptar la invitación a formar parte del equipo de trabajo de Inventarios Xpress?
                        </p>
                        <div class="form-group text-center p-b-20">
                            <div class="col-xs-12">
                                <button class="btn btn-primary btn-lg btn-block btn-rounded text-uppercase waves-effect waves-light" v-on:click="AcceptInvitation" type="button"> Aceptar </button>
                                <button class="btn btn-link btn-lg btn-block btn-rounded text-uppercase waves-effect waves-light" v-on:click="this.$router.push('/login')" type="button"> Cancelar </button>

                            </div>
                        </div>
                        <div class="form-group m-b-0">
                            <div class="col-sm-12 text-center">
                                ¿Ya tiene una cuenta? <router-link :to="{name: 'login'}" class="text-info m-l-5"><b> Iniciar Sesión </b></router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div class="col-lg-4 col-md-3 col-0">
        </div>

    </div>

</template>

<script>
    import cuentaService from '@/app/login/services/cuentaService.js';
    import config from '@/config.js';

    export default {
        data() {
            return {
                usuario: {
                    usuario_id: this.$route.params.cuenta,
                    login: '',
                    nombre: '',
                    password:'',
                    tipo: '',
                    Aplicacion: config.app_cod,
                    confirmacion: this.$route.params.codigo,
                    ciaFavorita: 0,
                    cta_id:''
                },

            }
        },
        methods: {

            AcceptInvitation: function () {

                this.$loading.show();

                cuentaService.postAcceptInviteExistingUser(this.usuario).then(response => {

                    if (response.data != null) {

                        switch (response.data) {
                            case 0:
                                this.$notify.Notificacion(1, "La invitacion se realizó con éxito, proceda a Iniciar Sesión");
                                this.$router.push('/login');
                                break;
                            case (-3 || -2):
                                this.$notify.Notificacion(3, "Invitación inválida. Solicite que le inviten nuevamente");
                                break;
                            case -4:
                                this.$notify.Notificacion(3, "Esta invitacion ya fue aceptada, proceda a Iniciar Sesión o recupere la contraseña");
                                break;
                            default:
                                this.$notify.Notificacion(2, "No se pudo procesar la invitación. Favor verifique con su proveedor");
                                break;
                        }

                    } else {
                        this.$notify.Notificacion(2, "No se pudo confirmar su cuenta. Favor verifique con su proveedor");
                    }

                    this.$loading.hide();

                }).catch(error => {
                    this.$notify.Notificacion(2, "No se pudo confirmar su cuenta. Favor verifique con su proveedor");
                    console.log('There was an error:', error);
                    this.$loading.hide();
                })
            }
        }
    };
</script>

<style>

    html, body {
        background-color: #EFEFFF !important;
    }

    .row {
        margin: 0px !important;
    }

</style>