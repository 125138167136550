import { useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { VueFinalModal } from 'vue-final-modal';

import config from '@/config.js';
import qs from 'qs'

import cuentaService from '../services/cuentaService';
import generalesService from '@/app/generales/services/generalesService.js';
import configuracionService from '@/app/configuracion/services/configuracionService.js';

export default {

    components: {
        VueFinalModal
    },

    setup() {
        return { v$: useVuelidate() }
    },

    data() {
        return {

            lists: {

                cuentas: [],

            },
            objects: {

                config: config,

                cuentaService: cuentaService,
                generales: generalesService,
                configuracion: configuracionService

            },
            variables: {

                showAccounts: false,
                cuentaSeleccionada: Object, // pa qué? !!!!!!
                usuario: {
                    usuario_id: 0,
                    //login: 'pruebas@facturelo.com',
                    //password: '7d157a7-',

                    //login: 'pruebasDesarrollo@facturelo.com',
                    //password: '1234567',


                    login: '',
                    password: '',

                    Aplicacion: config.app_cod
                }

            }

        }
    },

    validations() {

        return {

            variables: {

                usuario: {
                    login: { required, email },
                    password: { required }
                },

                diasVencido: 0,
                showPlanVencimientoModal: false

            }


        }

    },

    methods: {

        VerificarCuentasLogin: function () {

            this.v$.$validate();

            if (!this.v$.$invalid) {

                this.$loading.show();

                var objetoService = this.variables.usuario;

                this.objects.cuentaService.CuentasUsuarioLogin(objetoService).then(callback => {

                    if (callback.ok) {

                        if (callback.obj.length == 1) {

                            this.Login(callback.obj[0]);

                        } else {

                            this.variables.showAccounts = true;
                            this.lists.cuentas = callback.obj;

                            this.$loading.hide();

                        }

                    } else {

                        this.$notify.Notificacion(2, callback.msg, "No se pudo iniciar sesión correctamente", "Iniciar Sesión");

                        this.$loading.hide();

                    }

                });

            } // Imprime en pantalla en cada campo el error

        },

        Login(cuenta) {

            this.$loading.show();

            // Para qué esto?
            //var form = document.getElementById("loginform");
            //if (!form.checkValidity()) {
            //    //console.log("Invalido");
            //    return;
            //}

            var objetoService = qs.stringify({
                grant_type: 'password',
                Username: this.variables.usuario.login,
                password: this.variables.usuario.password,
                app_codigo: this.objects.config.app_cod,
                cta_id: cuenta.Email
            });

            cuentaService.Token(objetoService).then(callback => {

                if (callback.ok) {

                    localStorage.setItem("tech_token", callback.obj.access_token);

                    let objetoServiceConfig = {
                        ciaId: callback.obj.cia
                    };

                    //Consula la configuracion si no la tiene la crea de lo contrario obtiene si afecta la contabilida
                    this.objects.configuracion.getOne(objetoServiceConfig).then(callbackConfig => {

                        if (callbackConfig.ok) {                            

                            // Se consulta Compañía porque es necesario que al cargar go.html tenga la moneda cargada en localStorage,
                            // aparte que es mejor hacerlo solo 1 vez que hacerlo siempre en TOPBAR, hice la misma lógica de moneda
                            // (la obtiene solo 1 vez, y la actualiza en caso de cambiar en la configuración)
                            this.objects.generales.getCompannia(objetoServiceConfig).then(callbackCia => {

                                if (callbackCia.ok) {

                                    this.objects.generales.GetPlanIdFromToken().then(callbackPlan => {

                                        if (callbackPlan.ok) {

                                            this.variables.showAccounts = false;

                                            //Guarda los datos en el localstorage

                                            //console.log(callback.obj);
                                            //console.log(callbackCia);
                                            //return;


                                            //callback.obj.diasVigente = -2;


                                            //console.log(callback.obj);
                                            //return;


                                            if (callback.obj.diasVigente < this.objects.config.vencimiento_plan_dia_gracia) {

                                                this.variables.diasVencido = callback.obj.diasVigente * -1;

                                                this.ModalPlanVencimientoShow();

                                                localStorage.removeItem("tech_token");

                                                this.$loading.hide();

                                                return;

                                            }



                                            let arrFecha = callback.obj.fechaVencimiento.split("/");
                                            let fechaVencimientoFormateada = this.$filters.FillWith0(2, arrFecha[1]) + "/" + this.$filters.FillWith0(2, arrFecha[0]) + "/" + arrFecha[2];

                                            localStorage.setItem("tech_token", callback.obj.access_token);
                                            localStorage.setItem("tech_refreshtoken", '');
                                            localStorage.setItem("tech_tokenexpires", callback.obj['.expires']);
                                            localStorage.setItem("tech_user", this.variables.usuario.login);
                                            localStorage.setItem("tech_userName", callback.obj.userName);
                                            localStorage.setItem("tech_uTipo", callback.obj.uTipo);
                                            localStorage.setItem("tech_cia", callback.obj.cia);
                                            localStorage.setItem("tech_userId", callback.obj.userId);
                                            localStorage.setItem("tech_usercIden", callback.obj.cIden);
                                            localStorage.setItem("tech_usercta_id", callback.obj.cta_id);
                                            localStorage.setItem("tech_planId", callbackPlan.obj);
                                            localStorage.setItem("tech_planNombre", callback.obj.planNombre);
                                            localStorage.setItem("tech_planVencimiento", fechaVencimientoFormateada);
                                            localStorage.setItem("tech_diasVigente", callback.obj.diasVigente);
                                            //localStorage.setItem("tech_ciaName", callback.obj.ciaName); // callback.obj.ciaName
                                            //localStorage.setItem("tech_ciaMoneda", callback.obj.cMonId); // Esto no trae nada
                                            //localStorage.setItem("tech_ciaPais", 60);
                                            //localStorage.setItem("tech_ciaMonedaConfiguracion", callback.obj.cMonId);



                                            // Cia

                                            localStorage.setItem("tech_ciaMoneda", callbackCia.obj.moneda_id);
                                            localStorage.setItem("tech_ciaPais", callbackCia.obj.pais_id);
                                            localStorage.setItem("tech_ciaName", callbackCia.obj.nombre);



                                            // Config

                                            localStorage.setItem("tech_ciaMonedaConfiguracion", callbackConfig.obj.idMoneda);
                                            localStorage.setItem("tech_actualizacionRapida", callbackConfig.obj.actualizacionRapida);


                                            window.location = "/go.html#";

                                        } else {

                                            localStorage.removeItem("tech_token");

                                            this.$notify.Notificacion(2, callbackPlan.msg, "No se pudo consultar la información del plan correctamente");

                                            this.$loading.hide();

                                        }

                                    });

                                } else {

                                    localStorage.removeItem("tech_token");

                                    if (callbackCia.obj == -1) {
                                        this.$notify.Notificacion(2, callbackCia.msg);
                                    } else {
                                        this.$notify.Notificacion(2, "No se pudo consultar la compannia. Favor consulte con su proveedor(" + callbackCia.msg + ").");
                                    }
                                }

                                this.$loading.hide();

                            });

                        } else {

                            localStorage.removeItem("tech_token");

                            this.$notify.Notificacion(2, callbackConfig.msg, "No se pudo consultar la configuración correctamente");

                            this.$loading.hide();

                        }

                    });



                } else {

                    this.$notify.Notificacion(2, callback.msg, "No se pudo iniciar sesión correctamente", "Iniciar Sesión");
                    this.$loading.hide();

                }

            });

        },

        ModalPlanVencimientoShow() {

            this.variables.showPlanVencimientoModal = true;

        },
        ModalPlanVencimientoClose() {

            this.variables.showPlanVencimientoModal = false;
            this.variables.diasVencido = 0;

        },


    }

};